export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Original fan token for Real Madrid fans',
    headline: 'Kings of Europe, fueled by $REAL fans',
    description: 'Elevate your game with $REAL token: empowering fans, igniting team spirit, and chart-topping success.',
    buttonLabel: 'Buy $REAL',
    imgStart: '',
    img: require('../../images/gif4.gif'),
    alt: 'Credit Card',
    link: 'https://raydium.io/swap/?inputMint=sol&outputMint=8s1w3Lrjh2w73V9nCCEnh2NUgMqV1cV451c74UDsc5kc',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Instant Setup',
    headline: 'Extremely quick onboarding process',
    description:
      "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
    buttonLabel: 'Learn More',
    imgStart: 'true',
    img: require('../../images/vini.gif'),
    alt: 'Vault',
    link: 'https://t.me/RealMadridSolanaPortal',
    start: 'true'
  };
  
  export const homeObjThree = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Sarah Jeni',

    topLine: 'More than a token, its a legend',
    headline: 'WE ARE CTO!',
    description: "Get $REAL to back Real Madrid in the Champions League finals and make your support felt on and off the pitch - let's dominate the field and the charts!",
    buttonLabel: 'Join Telegram',
    imgStart: 'start',
    img: require('../../images/vini.gif'),
    alt: 'Vault',
    link: 'https://t.me/RealMadridSolanaPortal',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Secure Database',
    headline: 'All your data is stored on our secure server',
    description:
      'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/svg-3.svg'),
    alt: 'Vault',
    link: '',
    start: 'true'
  };